import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Fa from '@fortawesome/react-fontawesome';
import faArrowLeft from '@fortawesome/fontawesome-free-solid/faArrowLeft';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import FrameContainer from './frame-container';
import Image from '../common/image';
import Loading from '../common/loading';

const GET_WORK = gql`
  query($id: String!) {
    getWork(id: $id) {
      id
      title
      previewDescription
      description
      year
      category
      staticLink
      realLink
      stack
      fields {
        id
        label
        value
      }
      images {
        public_id
        caption
      }
    }
  }
`;

const Work = ({ match }) => (
  <Query query={GET_WORK} variables={{ id: match.params.id }}>
    {({ loading, data }) => {
      if (loading) return <Loading />;

      if (!data.getWork) {
        return <Redirect to="/404" />;
      }

      const {
        title,
        previewDescription,
        description,
        year,
        category,
        staticLink,
        images,
        realLink,
        stack,
        fields,
      } = data.getWork;

      return (
        <div className="work">
          <Helmet>
            <title>Eugene Bulgakov - Portfolio - {title}</title>
          </Helmet>
          <div className="container">
            <div className="work_breadcrumbs">
              <Link to="/works">
                <Fa icon={faArrowLeft} /> Back to list
              </Link>
            </div>
            <h1 className="work_title">{title}</h1>
            <div className="work_props">
              <dl className="work_prop">
                <dt>Categoty:</dt>
                <dd>
                  <Link
                    to={{
                      pathname: '/works',
                      search: `?section_key=category&section_value=${category}`,
                    }}
                  >
                    {category}
                  </Link>
                </dd>
              </dl>
              <dl className="work_prop">
                <dt>Year:</dt>
                <dd>
                  <Link
                    to={{ pathname: '/works', search: `?section_key=year&section_value=${year}` }}
                  >
                    {year === '2000' ? '…–2010' : year}
                  </Link>
                </dd>
              </dl>
              {realLink && (
                <dl className="work_prop">
                  <dt>Link to site:</dt>
                  <dd>
                    <a href={realLink} target="_blank">
                      {realLink}
                    </a>
                  </dd>
                </dl>
              )}
              {stack && (
                <dl className="work_prop">
                  <dt>Used technologies:</dt>
                  <dd>
                    {stack.map((item, idx) => (
                      <span key={idx}>
                        {item}
                        {idx < stack.length - 1 && <span>, </span>}
                      </span>
                    ))}
                  </dd>
                </dl>
              )}
            </div>
            <p className="work_description">{description || previewDescription}</p>
          </div>

          {staticLink && (
            <div>
              <hr />
              <div className="container">
                <p>
                  <a href={staticLink} target="_blank">
                    Open in new tab
                  </a>
                </p>
              </div>
              <FrameContainer link={staticLink} />
            </div>
          )}

          {fields.map(item => {
            if (item.label !== 'static') {
              return null;
            }
            return (
              <div key={item.id} className="work_extra-static-prop">
                <hr />
                <div className="container">
                  <h2>Extra Version</h2>
                  <p>
                    <a href={item.value} target="_blank">
                      Open in new tab
                    </a>
                  </p>
                </div>
                <FrameContainer link={item.value} />
              </div>
            );
          })}

          <div className="container">
            <Row>
              {images.map(image => (
                <Col className="screen-preview" xs={12} key={image.public_id}>
                  <Image className="screen-preview_image" publicId={image.public_id} />

                  <div className="screen-preview_title">{image.caption}</div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      );
    }}
  </Query>
);

Work.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default Work;
