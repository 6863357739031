import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Skills = () => (
  <div className="container skills">
    <h1>Skills</h1>
    <p>
      I work in three main activities as HTML, CSS, Javascript. In their activities I use modern
      technologies. I use as pure technologies and with framework and libraries, like:
    </p>

    <Row>
      <Col xs={4}>
        <h2>HTML / CSS</h2>
        <ul>
          <li>SASS / SCSS</li>
          <li>BEM</li>
          <li>Bootstrap framework</li>
          <li>Slim templates</li>
        </ul>
      </Col>
      <Col xs={4}>
        <h3>JS</h3>
        <ul>
          <li>React</li>
          <li>Redux</li>
          <li>Ramda</li>
          <li>Jest</li>
          <li>Enzyme</li>
          <li>AngularJS</li>
        </ul>
      </Col>
      <Col xs={4}>
        <h3>Work environment</h3>
        <ul>
          <li>GIT</li>
          <li>Firebase</li>
          <li>Heroku</li>
        </ul>
      </Col>
    </Row>

    <h2>I am familiar with:</h2>
    <ul>
      <li>Ruby on Rails</li>
      <li>VueJS</li>
      <li>GraphQL</li>
      <li>Design patterns</li>
      <li>Functional programming</li>
    </ul>

    <h2>Extra hard skills</h2>
    <ul>
      <li>Pair programming</li>
      <li>Agile techniques</li>
      <li>Code style</li>
      <li>Code review</li>
    </ul>
  </div>
);

export default Skills;
