import React, { Component } from 'react';
import { string } from 'prop-types';

const FrameContainer = class extends Component {
  static propTypes = {
    link: string.isRequired,
  };
  state = {
    frameHeight: '',
  };
  componentDidMount() {
    window.addEventListener('message', this.setFrameHeight, false);
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.setFrameHeight);
  }
  setFrameHeight = e => {
    if (e.data[0] === 'setHeight') {
      this.setState({
        frameHeight: e.data[1],
      });
    }
  };
  render() {
    const { link } = this.props;
    return (
      <iframe
        height={this.state.frameHeight}
        className="work_frame-container"
        title="Page"
        src={link}
      />
    );
  }
};

export default FrameContainer;
