import React from 'react';
import { Link } from 'react-router-dom';

const P404 = () => (
  <div className="page-404">
    <h1>404 page not found</h1>
    <p>
      <Link to="/">&larr; back to main page</Link>
    </p>
  </div>
);

export default P404;
