import React from 'react';
import Separator from '../common/separator';
import Intro from './intro';
import Competencies from './competencies';
import LastAdded from './last-added';
import CurrentJob from './current-job';
import Contacts from './contacts';

const Home = () => (
  <div>
    <Intro />
    <Competencies />
    <Separator />
    <LastAdded />
    <Separator />
    <CurrentJob />
    <Separator />
    <Contacts />
  </div>
);

export default Home;
