import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Logo from './logo';

const Header = class extends Component {
  state = {
    isBlownMenu: false,
    isShownMenu: false,
  };
  toggleShowModal = () => {
    this.setState({
      isBlownMenu: !this.state.isBlownMenu,
    });
    setTimeout(() => {
      this.setState({
        isShownMenu: !this.state.isShownMenu,
      });
    }, 100);
  };
  render() {
    return (
      <header className="header">
        <div className="container-fluid">
          <div className="header_logo">
            <Logo />
          </div>
          <button
            aria-label="Toggle Menu"
            className="header_menu-opener"
            onClick={this.toggleShowModal}
          />

          <div
            className={cn('effeckt-wrap effeckt-modal-wrap', {
              'from-below': this.state.isBlownMenu,
              'effeckt-show': this.state.isShownMenu,
            })}
          >
            <div className="modal-menu effeckt-content effeckt-modal">
              <button className="modal-menu_close-wrapper" onClick={this.toggleShowModal}>
                <span className="modal-menu_close" />
              </button>
              <ul className="modal-menu_list">
                <li className="modal-menu_list-item">
                  <Link onClick={this.toggleShowModal} to="/works">
                    Works
                  </Link>
                </li>
                <li className="modal-menu_list-item">
                  <a
                    href="https://www.linkedin.com/in/eugene-bulgakov"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    LinkedIn Profile
                  </a>
                </li>
                <li className="modal-menu_list-item">
                  <a
                    href="/static/cv_eugene_bulgakov.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Curriculum Vitae
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <button onClick={this.toggleShowModal} className="effeckt-modal-overlay" />
        </div>
      </header>
    );
  }
};

export default Header;
