import React from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

const Separator = ({ modificatorClass }) => (
  <hr className={cn('section-separator', modificatorClass)} />
);

Separator.defaultProps = {
  modificatorClass: '',
};
Separator.propTypes = {
  modificatorClass: string,
};
export default Separator;
