import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CurrentJob = () => (
  <div className="container">
    <section className="current-job">
      <h1 className="current-job_title">My current job position</h1>
      <Row>
        <Col sm={6}>
          <a
            href="https://www.jiffyshirts.com/"
            className="current-job_logo-wrapper"
            title="JiffyShirts"
          >
            <img className="current-job_logo" src="/img/jiffyshirts.svg" alt="JiffyShirts Logo" />
          </a>
          <h4 className="current-job_subtitle">Front End Web Developer</h4>
          <dl className="current-job_info">
            <dt>Company name: </dt>
            <dd>
              <a href="https://www.jiffyshirts.com/">JiffyShirts</a>
            </dd>
          </dl>
          <dl className="current-job_info">
            <dt>Employed: </dt>
            <dd>November 2018 &mdash; Present</dd>
          </dl>
          <dl className="current-job_info">
            <dt>Location: </dt>
            <dd>Remote</dd>
          </dl>
        </Col>
        <Col sm={6}>
          <p>My responsibility:</p>
          <ul className="current-job_list">
            <li>Responsive and bulletproof interface.</li>
            <li>Optimization JS code and increased performance of the site.</li>
            <li>Present colleagues with new practices of writing code.</li>
            <li>Writing JS-unit code.</li>
            <li>Estimate tasks and follow them on sprint tasks.</li>
            <li>Help designers to improve UI.</li>
            <li>Writing RoR code and GitHub Actions.</li>
          </ul>
        </Col>
      </Row>
    </section>
  </div>
);

export default CurrentJob;
