import React from 'react';
import { hot } from 'react-hot-loader';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import client from './client';
import Header from './common/header';
import Footer from './common/footer';
import Home from './home';
import P404 from './404';
import Work from './work';
import Works from './works';
import Contacts from './contacts';
import About from './about';
import Skills from './skills';

const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <div className="sticky-footer">
        <div className="sticky-footer_page">
          <Helmet>
            <title>Eugene Bulgakov - Home page</title>
          </Helmet>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/work/:id" component={Work} />
            <Route path="/works" component={Works} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/about" component={About} />
            <Route path="/skills" component={Skills} />
            <Route component={P404} />
          </Switch>

          <div className="sticky-footer_footer-damper" />
        </div>
        <div className="sticky-footer_footer">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  </ApolloProvider>
);

export default hot(module)(App);
