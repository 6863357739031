import React from 'react';

const Loading = () => (
  <div className="loading">
    <div className="loading_wrapper">
      <svg className="loading_svg" viewBox="0 0 50 50">
        <circle
          className="loading_circle"
          cx="25"
          cy="25"
          fill="none"
          r="20"
          stroke="currentColor"
        />
      </svg>
      <div className="loading_text">Loading...</div>
    </div>
  </div>
);

export default Loading;
