import React, { Component } from 'react';
import { shape } from 'prop-types';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Image from '../common/image';
import Loading from '../common/loading';
import Filter from './filter';

const WORKS = gql`
  query($searchKey: String, $searchValue: String) {
    works(searchKey: $searchKey, searchValue: $searchValue) {
      id
      url
      title
      previewDescription
      previewImage {
        public_id
      }
    }
  }
`;

const Works = class extends Component {
  static defaultProps = {
    location: {},
  };
  static propTypes = {
    location: shape(),
  };
  state = {
    searchKey: '',
    searchValue: '',
  };
  componentWillMount() {
    this.updateSearchQuery(this.props.location);
  }
  componentWillReceiveProps(nextProps) {
    this.updateSearchQuery(nextProps.location);
  }
  updateSearchQuery = location => {
    const params = new URLSearchParams(location.search);

    this.setState({
      searchKey: params.get('section_key'),
      searchValue: params.get('section_value'),
    });
  };
  filteredTitle = () => {
    let title = 'All works';

    if (this.state.searchKey && this.state.searchValue) {
      title = (
        <span>
          Works in <b>{this.state.searchKey}</b> ({this.state.searchValue})
        </span>
      );
    }

    if (this.state.searchValue === '2000') {
      title = 'List of works before 2011';
    }
    return title;
  };
  render() {
    const { searchKey, searchValue } = this.state;

    return (
      <Query query={WORKS} variables={{ searchKey, searchValue }}>
        {({ loading, data }) => {
          const { works } = data;

          if (loading && !works) return <Loading />;

          return (
            <div>
              <Helmet>
                <title>Eugene Bulgakov - Portfolio</title>
              </Helmet>
              {loading && works && <Loading />}

              <div className="works">
                <div className="container">
                  <Row className="works_row">
                    <Col sm={4} md={3}>
                      <Filter />
                    </Col>
                    <Col sm={8} md={9}>
                      <h1 className="works_title">{this.filteredTitle()}</h1>
                      <Row className="works_list">
                        {works.map(work => (
                          <Col md={6} lg={4} key={work.id}>
                            <Link to={`/work/${work.url}`} className="work-preview">
                              <figure className="effeckt-caption" data-effeckt-type="cover-fade">
                                <Image
                                  className="work-preview_img"
                                  publicId={work.previewImage.public_id}
                                  width="300"
                                  height="300"
                                  crop="scale"
                                />
                                <figcaption className="work-preview_caption">
                                  <div className="effeckt-figcaption-wrap">
                                    <h3 className="work-preview_title">{work.title}</h3>
                                    <p className="work-preview_description">
                                      {work.previewDescription}
                                    </p>
                                  </div>
                                </figcaption>
                              </figure>
                            </Link>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
};

export default Works;
