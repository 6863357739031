import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

export default new ApolloClient({
  link: new HttpLink({
    uri:
      process.env.NODE_ENV !== 'production'
        ? 'http://localhost:5000/bulgakov-staging/us-central1/api/graphql'
        : 'https://us-central1-bulgakov-staging.cloudfunctions.net/api/graphql',
  }),
  cache: new InMemoryCache(),
});
