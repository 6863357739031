import React from 'react';
import Fa from '@fortawesome/react-fontawesome';
import faObjectGroup from '@fortawesome/fontawesome-free-regular/faObjectGroup';
import faCreditCard from '@fortawesome/fontawesome-free-regular/faCreditCard';
import faFileCode from '@fortawesome/fontawesome-free-regular/faFileCode';
import { Row, Col } from 'react-bootstrap';
import LinkMore from './link-more';

const Competencies = () => (
  <div className="container">
    <section className="competencies">
      <h1 className="competencies_title">My competencies</h1>
      <Row>
        <Col sm={4}>
          <Fa className="competencies_icon" icon={faObjectGroup} />
          <h2 className="competencies_subtitle">Design adaptation</h2>
          <p>
            Sometimes, the layouts do not entirely match the actual site data. I have a considerable
            experience with adaptation and correction design in business. I can draw skipped
            elements or adapt current them to current business tasks.
          </p>
        </Col>
        <Col sm={4}>
          <Fa className="competencies_icon" icon={faFileCode} />
          <h2 className="competencies_subtitle">Front end development</h2>
          <p>
            I have more than 15 years of experience with front-end technologies. I like to do it,
            and I every time learn something new. In my work, I use only proven technologies and
            best practices for web engineering. Anyway, if I do not know something, I will learn it
            as fast as I can.
          </p>
        </Col>
        <Col sm={4}>
          <Fa className="competencies_icon" icon={faCreditCard} />
          <h2 className="competencies_subtitle">E-commerce</h2>
          <p>
            I work with e-commerce last 6 years. During this time I have studied to work with many
            CMS including Shopify, BigCommerce, Spree, Bitrix, and others. It is hard, but it
            interesting process with a lot of nuances, which allows for creating a working shop for
            selling goods.
          </p>
        </Col>
      </Row>
    </section>
  </div>
);

export default Competencies;
