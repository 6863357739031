import React from 'react';
import Logo from './logo';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer_inner-wrapper">
        <div className="footer_logo">
          <Logo />
        </div>
        <div className="footer_credentails">Made with love &copy; 2018&ndash;2023</div>
      </div>
    </div>
  </footer>
);

export default Footer;
