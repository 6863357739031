import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fa from '@fortawesome/react-fontawesome';
import faAt from '@fortawesome/fontawesome-free-solid/faAt';
import faMapMarker from '@fortawesome/fontawesome-free-solid/faMapMarker';
import faLinkedin from '@fortawesome/fontawesome-free-brands/faLinkedin';
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebook';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faVk from '@fortawesome/fontawesome-free-brands/faVk';
import faTelegram from '@fortawesome/fontawesome-free-brands/faTelegram';
import faSkype from '@fortawesome/fontawesome-free-brands/faSkype';
import faGithub from '@fortawesome/fontawesome-free-brands/faGithub';

const Contacts = () => (
  <div className="container">
    <section className="contacts">
      <h1 className="contacts_title">Contacts and social</h1>
      <Row>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Email:</dt>
            <dd>
              <a href="mailto:me@ebulgakov.com">
                <Fa className="contacts_icon" icon={faAt} />
                me@ebulgakov.com
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Telegram:</dt>
            <dd>
              <a rel="noopener noreferrer" target="_blank" href="https://t.me/ebulgakov">
                <Fa className="contacts_icon" icon={faTelegram} />
                @ebulgakov
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>LinkedIn:</dt>
            <dd>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/ebulgakov/"
              >
                <Fa className="contacts_icon" icon={faLinkedin} />
                ebulgakov
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Github:</dt>
            <dd>
              <a rel="noopener noreferrer" target="_blank" href="https://github.com/zoom-samara">
                <Fa className="contacts_icon" icon={faGithub} />
                ebulgakov
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Facebook:</dt>
            <dd>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100000705951180"
              >
                <Fa className="contacts_icon" icon={faFacebook} />
                Eugene Bulgakov
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Instagram:</dt>
            <dd>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/eugene.bulgakov/"
              >
                <Fa className="contacts_icon" icon={faInstagram} />
                @eugene.bulgakov
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Vkontakte:</dt>
            <dd>
              <a rel="noopener noreferrer" target="_blank" href="https://vk.com/bulgakove">
                <Fa className="contacts_icon" icon={faVk} />
                vk.com/bulgakove
              </a>
            </dd>
          </dl>
        </Col>
        <Col sm={6} lg={4}>
          <dl className="contacts_item">
            <dt>Location:</dt>
            <dd>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.google.com/maps/@41.7321292,44.7613927,14z"
              >
                <Fa className="contacts_icon" icon={faMapMarker} />
                Tbilisi, Georgia
              </a>
            </dd>
          </dl>
        </Col>
      </Row>
    </section>
  </div>
);

export default Contacts;
