import React from 'react';
import { string } from 'prop-types';
import Fa from '@fortawesome/react-fontawesome';
import faCaretRight from '@fortawesome/fontawesome-free-solid/faCaretRight';

const LinkMore = ({ link, title }) => (
  <div className="link-more">
    <a className="link-more_link" href={link}>
      <span className="link-more_icon">
        <Fa icon={faCaretRight} />
      </span>
      {title}
    </a>
  </div>
);

LinkMore.propTypes = {
  link: string.isRequired,
  title: string.isRequired,
};

export default LinkMore;
