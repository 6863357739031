import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import LinkMore from './link-more';
import Image from '../common/image';

const LAST_ADDED = gql`
  query {
    lastWork {
      id
      title
      staticLink
      realLink
      year
      category
      url
      previewDescription
      previewImage {
        public_id
      }
    }
  }
`;

const LastAdded = () => (
  <div className="container">
    <section className="last-added">
      <h1 className="last-added_title">Last added work</h1>
      <Query query={LAST_ADDED}>
        {({ loading, data }) => {
          if (loading) return <div className="last-added_loading" />;

          const {
            title,
            id,
            previewImage,
            staticLink,
            realLink,
            year,
            category,
            url,
            previewDescription,
          } = data.lastWork;
          return (
            <Row>
              <Col xs={12} sm={6} md={5} lg={4}>
                <Link to={`/work/${url}`} className="last-added_image-wrapper" title={title}>
                  <Image
                    alt={title}
                    publicId={previewImage.public_id}
                    width="350"
                    height="200"
                    crop="crop"
                  />
                </Link>
              </Col>
              <Col xs={12} sm={6} md={7} lg={8}>
                <h2 className="last-added_subtitle">
                  <Link to={`/work/${url}`}>{title}</Link>
                </h2>
                <Row>
                  <Col sm={4} md={4}>
                    <dl>
                      <dt>
                        <h4>
                          Link to&nbsp;
                          {realLink ? <span>site</span> : <span>demo</span>}:
                        </h4>
                      </dt>
                      <dd>
                        {realLink ? (
                          <a rel="noopener noreferrer" target="_blank" href={realLink}>
                            {realLink}
                          </a>
                        ) : (
                          <a rel="noopener noreferrer" target="_blank" href={staticLink}>
                            Demo link
                          </a>
                        )}
                      </dd>
                    </dl>
                  </Col>
                  <Col sm={4} md={4}>
                    <dl>
                      <dt>
                        <h4>Year:</h4>
                      </dt>
                      <dd>
                        <Link to={`/works/?section_key=year&section_value=${year}`}>
                          {year === '2000' ? '…–2010' : year}
                        </Link>
                      </dd>
                    </dl>
                  </Col>
                  <Col sm={4} md={4}>
                    <dl>
                      <dt>
                        <h4>Category:</h4>
                      </dt>
                      <dd>
                        <Link to={`/works/?section_key=category&section_value=${category}`}>
                          {category}
                        </Link>
                      </dd>
                    </dl>
                  </Col>
                </Row>
                <h4 className="last-added_subsubtitle">Short description</h4>
                <p>{previewDescription}</p>
              </Col>
            </Row>
          );
        }}
      </Query>
    </section>
    <LinkMore link="/works" title="Show all works" />
  </div>
);

export default LastAdded;
