import React from 'react';
import Fa from '@fortawesome/react-fontawesome';
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';

const Header = () => (
  <header className="intro">
    <div className="intro_inner-wrapper">
      <div className="intro_name">Eugene Bulgakov</div>
      <div className="intro_about">
        Front End Web Developer, Open Source guy
        <br />
        and just a good man
      </div>
    </div>
    <div className="intro_mouse-wrapper">
      <div className="intro_mouse" />
      <Fa className="intro_chevron -i0" icon={faChevronDown} />
      <Fa className="intro_chevron -i1" icon={faChevronDown} />
      <Fa className="intro_chevron -i2" icon={faChevronDown} />
    </div>
  </header>
);

export default Header;
