import React from 'react';
import { Link } from 'react-router-dom';

const Filter = () => (
  <div className="filter">
    <h2 className="filter_title">Filter</h2>
    <dl className="filter_group">
      <dt className="filter_label">Category</dt>
      <dd className="filter_controls">
        <div className="filter_control">
          <Link to="?section_key=category&section_value=3D">3D</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=category&section_value=formatting">Formatting</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=category&section_value=full%20stack">Full stack</Link>
        </div>
      </dd>
    </dl>
    <dl className="filter_group">
      <dt className="filter_label">Year</dt>
      <dd className="filter_controls">
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2018">2018</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2017">2017</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2016">2016</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2015">2015</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2014">2014</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2013">2013</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2012">2012</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2011">2011</Link>
        </div>
        <div className="filter_control">
          <Link to="?section_key=year&section_value=2000">&hellip;&ndash;2010</Link>
        </div>
      </dd>
    </dl>
    <div className="filter_buttons">
      <Link to="/works" className="btn btn-default btn-block filter_button -clear">
        Clear
      </Link>
    </div>
  </div>
);

export default Filter;
